import React, { useState, useEffect, createContext, useContext } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const getServerSiteSetting = () => {
    return {
        signInTimeOut: 5,
        signOutWarningTimedelay: 1,
    }
}

const useCurrentUser = () => {
    const { signInTimeOut: signInTimeOutSet, signOutWarningTimedelay } = getServerSiteSetting();
    const signInTimeOut = signInTimeOutSet - signOutWarningTimedelay;

    const [authUser, setAuthUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [signOutTime, setSignOutTime] = useState(new Date());

    const refreshToken = async () => {
        const response = await axios.get('/auth', {withCredentials: true});
        const { user, currTime } = response.data;
        if ( !user ) return null;

        const resDate = new Date(currTime);
        setAuthUser({ currentuser: user });
        setSignOutTime(new Date(resDate.getTime() + signInTimeOut * 60000));
        return user;
    };

    const sendCode = async (email, password, confirmPassword) => {
        const response = await axios.post('/auth/code', { email , password, confirmPassword },
            { withCredentials: true });
        const { success } = response.data;
        if ( !success ) return toast.error(`Error sending email to ${email}`, 
            { theme: "colored" });
        toast.success(`Code sent to ${email}`, { theme: "colored" });
    };

    const resetPassword = async (email, password, confirmPassword, code) => {
        const response = await axios.post('/auth/reset', { email , password, confirmPassword, code },
            { withCredentials: true });
        const { success } = response.data;
        if ( !success ) return toast.error('Error resetting password', { theme: "colored" });
        toast.success('Password reset successfully, Please log in', { theme: "colored" });
    };

    const signInWithEmailAndPassword = async (email, password) => {
        const response = await axios.post('/auth', { email , password },
            { withCredentials: true });
        const { user, currTime } = response.data;

        if ( !user ) return toast.error('Error logging in', { theme: "colored" });

        toast.success('Logged in successfully', { theme: "colored" });
        const resDate = new Date( currTime);
        setAuthUser({ currentuser: user });
        setSignOutTime(new Date(resDate.getTime() + signInTimeOut * 60000));
        return user;
    };

    const createUserWithEmailAndPassword = async (email, password) => {
        return await fetch('/api/users/signup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, password })
        })
            .then(res => res.json())
            .then(res => {
                const resDate = new Date(res.currTime);
                setAuthUser({ currentuser: res.success });
                setSignOutTime(new Date(resDate.getTime() + signInTimeOut * 60000));
                return res;
            });
    };

    const signOut = async () => {
        const response = await axios.post('/auth/signout', {withCredentials: true});
        const err = response.error;
        if ( err ) return toast.error('Error logging out', { theme: "colored" });

        setAuthUser(null);
        toast.success('Logged out successfully', { theme: "colored" });
        window.location.reload();
    };

    const getUser = async () => {
        const response = await axios.get('/auth', {withCredentials: true});
        const res = response.data;
        const err = response.error;

        if ( err ) {
            setLoading(false);
            setAuthUser({});
        }

        if (!res.currentuser) return;

        const resDate = new Date(res.currTime);
        setSignOutTime(new Date(resDate.getTime() + signInTimeOut * 60000));
        setAuthUser(res);
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        getUser();
    }, []);

    useEffect(() => {
        if (!authUser) {
            getUser();
        };
    }, [authUser]);


    return {
        authUser,
        loading,
        signOutTime,
        refreshToken,
        sendCode,
        resetPassword,
        signInWithEmailAndPassword,
        createUserWithEmailAndPassword,
        signOut
    };
};

const authUserContext = createContext({
    authUser: null,
    loading: false,
    signOutTime: new Date(),
    refreshToken: async () => { },
    sendCode: async () => { },
    resetPassword: async () => { },
    signInWithEmailAndPassword: async () => { },
    createUserWithEmailAndPassword: async () => { },
    signOut: async () => { }
});

export function AuthUserProvider({ children }) {
    const auth = useCurrentUser();
    return <authUserContext.Provider value={auth}>
        {children}
    </authUserContext.Provider>;
}

export const useAuth = () => useContext(authUserContext);