import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { CardWrapper, CardHeader, CardHeading, CardBody, CardFieldset, CardInput, CardButton } from "./card.js";

export const StyledCardWrapper = styled(CardWrapper)`
    width: 400px;
    padding: 40px;
    @media only screen and (max-width: 768px) {
        &{
            width: 100%;
            padding: 20px 0;
        }
    }
`;

const AddMentor = ({finishAction}) => {
    const [mentorEmail, setMentorEmail] = useState('');
    const [mentorName, setMentorName] = useState('');

    const handleAddMentor = () => {
        if (!mentorEmail || !mentorName) {
            toast.error('Please fill in all fields', {
                theme: "colored"
            });
            return;
        }
        const mentor = {
            email: mentorEmail,
            mentor_name: mentorName
        };
        axios.post('/mentor/', mentor)
            .then(res => {
                finishAction && finishAction(mentor);
            })
            .catch(err => {
                console.log(err);
                toast.error('Something went wrong', {
                    theme: "colored"
                });
            });
    };

    return (
        <StyledCardWrapper>
            <ToastContainer />
            <CardHeader>
                <CardHeading>Add New Mentor</CardHeading>
            </CardHeader>
            <CardBody>
                <CardFieldset>
                    <CardInput placeholder="Email" type="text" required value={mentorEmail} 
                        onChange={(e) => setMentorEmail(e.target.value)}/>
                </CardFieldset>

                <CardFieldset>
                    <CardInput placeholder="Username" type="text" required value={mentorName}
                        onChange={(e) => setMentorName(e.target.value)}/>
                </CardFieldset>

                <CardFieldset>
                    <CardButton type="button" onClick={finishAction}>Cancel</CardButton>
                </CardFieldset>
                <CardFieldset>
                    <CardButton type="button" onClick={handleAddMentor}>Add Mentor</CardButton>
                </CardFieldset>
            </CardBody>
        </StyledCardWrapper>
    )
}

export default AddMentor;