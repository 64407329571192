import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { TextField, IconButton, Divider, Button, 
    DialogTitle, DialogContentText, DialogContent, 
    DialogActions, Dialog } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Clear';
import { CardWrapper, CardBody } from "./card.js";
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';


export const StyledTextField = styled(TextField)`
    &&{
        input {
            font-size: 0.8rem;
            padding: 7px 7px;

            @media only screen and (max-width: 768px) {
                &{
                    padding: 3px 4px;
                }
            }
        }
        label {
            transform: translate(14px, 4px) scale(1);
            @media only screen and (max-width: 768px) {
                &{
                    transform: translate(10px, 5px) scale(0.75);
                }
            }
        }
        label.Mui-focused {
            color: grey;
            transform: translate(14px, -7px) scale(0.75);
        }
        .MuiOutlinedInput-root {
            fieldset {
                border-color: black;
                :hover {
                    border-color: grey;
                }
                .Mui-focused {
                    border-color: black;
                }
            }
        }
    }
`;

export const StyledCardWrapper = styled(CardWrapper)`
    width: 70%;
    margin-top: 10px;
    border-radius: 10px;
    padding: 10px;
    @media only screen and (max-width: 768px) {
        &{
            width: 100%;
            padding: 2px;
        }
    }
`;

export const StyledTable = styled(Table)`
    cursor: pointer;
    font-size: 0.9rem;

    @media only screen and (max-width: 768px) {
        &{
            width: 100%;
            font-size: 0.8rem;
        }
    }
`;

export const StyledIconButton = styled(IconButton)`
    &&{
        padding: 6px;
        background-color: #838383;
        svg {
            color: white;
            :hover {
                color: grey;
            }
        }
        @media only screen and (max-width: 768px) {
            &{
                padding: 4px;
            }
        }
    }
`;

export const SearchContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    @media only screen and (max-width: 768px) {
        &{
            display: flex;
            gap: 10px;
        }
    }
`;

const VIEW = 'VIEW';
const DELETE = 'DELETE';

const dummyData = [{
    "mentee_id": 3,
    "mentee_email": "love@gmail.com",
    "mentee_name": "Love Sorell",
    "last_updated": "2020-01-08T09:51:56.000Z",
    "mentor": 1,
    "update_entity": "Mentor",
    "is_completed": 0
},
{
    "mentee_id": 4,
    "mentee_email": "hope@gmail.com",
    "mentee_name": "Robert Hope",
    "last_updated": "2020-01-08T09:51:56.000Z",
    "mentor": 1,
    "update_entity": "Mentee",
    "is_completed": 0
}]

const ConfirmDeleteDialog = ({open, handleDialogAction}) => {
    return (
        <div>
            <Dialog open={open} onClose={() => handleDialogAction()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    {"Delete Mentor?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you wanto to delete this mentee?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogAction()}>Cancel</Button>
                    <Button onClick={() => handleDialogAction(DELETE)} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const DatatableComponent = ({ rowSelected, mentor, isAdmin }) => {
    const [data, setData] = useState([]);
    const [dataDisplay, setDataDisplay] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState({ name: '', email: '' });
    const [open, setOpen] = React.useState(false);
    const [selectedRow, setSelectedRow] = useState(null);


    const handleDialogAction = (action) => {
        if (action) handleRemoveMentee(selectedRow);
        setOpen(false);
        setSelectedRow(null);
    };

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            await axios.get('/mentee', { withCredentials: true })
                .then(res => {
                    setData(res.data.length !== 0 ? res.data : dummyData);
                    setIsLoading(false);
                    setDataDisplay(res.data.length !== 0 ? res.data : dummyData);
                })
                .catch(err => {
                    console.log(err);
                    setData(dummyData);
                    setDataDisplay(dummyData);
                    setIsLoading(false);
                });
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (!mentor) return setDataDisplay(data);
        const thisDataDisplay = data.filter(
            d => d.mentor === mentor.mentor_id)
        setDataDisplay(thisDataDisplay);
    }, [mentor]);

    const handleRowClick = (rowData) => {
        rowSelected && rowSelected(rowData);
    };

    const handleSortColumn = (columnKey, sortOrder) => {
        const sortedData = data.sort((a, b) => {
            if (sortOrder === 'asc') {
                return a[columnKey] > b[columnKey] ? 1 : -1;
            } else {
                return a[columnKey] < b[columnKey] ? 1 : -1;
            }
        });
        setData(sortedData);
    };

    const handleClear = () => {
        setSearch({ name: '', email: '' });
        setDataDisplay(data);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        let filteredData = [...data];

        if (search.email)
            filteredData = filteredData.filter(mentee => mentee.mentee_email
                .toLowerCase()
                .includes(search.email.toLowerCase()));
        if (search.name)
            filteredData = filteredData.filter(mentee => mentee.mentee_name
                .toLowerCase()
                .includes(search.name.toLowerCase()));
        setDataDisplay(filteredData);
    };

    const handleSearchChange = (event) => {
        const { name, value } = event.target;
        setSearch({ ...search, [name]: value });
    };

    const handleRemoveMentee = (mentee) => {
        async function removeMentee() {
            if (!mentee) return;
            setIsLoading(true);
            const { mentee_id } = mentee;
            await axios.delete(`/mentee/admin/delete/${mentee_id}`, 
                { withCredentials: true })
            .then(res => {
                console.log({ res });
                const newData = data.filter(m => m.mentee_id !== mentee_id);
                setData(newData.length !== 0 ? newData : dummyData);
                setIsLoading(false);
                setDataDisplay(newData.length !== 0 ? newData : dummyData);
            })
            .catch(err => {
                console.log(err);
                setData(dummyData);
                setDataDisplay(dummyData);
                setIsLoading(false);
            });
        }
        removeMentee();
    }


    return (
        <StyledCardWrapper>
            <ConfirmDeleteDialog open={open} handleDialogAction={handleDialogAction} />
            <CardBody>
                <Divider style={{ margin: '0 0 10px 0' }} />
                <SearchContainer>
                    <StyledTextField name="email" label="Email" autoComplete='off'
                        onChange={handleSearchChange} value={search.email} />
                    <StyledTextField name="name" label="Name" autoComplete='off'
                        onChange={handleSearchChange} value={search.name} />
                    <StyledIconButton onClick={handleSearch}>
                        <SearchIcon />
                    </StyledIconButton>
                    <StyledIconButton onClick={handleClear}>
                        <CancelIcon />
                    </StyledIconButton>
                </SearchContainer>
                <Divider style={{ margin: '10px 0' }} />
                <StyledTable height={530} data={dataDisplay}
                    loading={isLoading} onSortColumn={handleSortColumn}>
                    <Column sortable fixed flexGrow={1}>
                        <HeaderCell>Name</HeaderCell>
                        <Cell dataKey="mentee_name" />
                    </Column>
                    <Column sortable flexGrow={1}>
                        <HeaderCell>Email</HeaderCell>
                        <Cell dataKey="mentee_email" />
                    </Column>
                    <Column sortable flexGrow={1}>
                        <HeaderCell>Updated</HeaderCell>
                        <Cell>
                            {(rowData, rowIndex) => {
                                return <div>
                                    {new Date(Date.parse(rowData.last_updated)).toLocaleString()}
                                </div>;
                            }}
                        </Cell>
                    </Column>
                    <Column sortable flexGrow={1}>
                        <HeaderCell>Last Updater</HeaderCell>
                        <Cell>
                            {(rowData, rowIndex) => {
                                return <div style={{
                                    color: rowData.update_entity === 'Mentor' ? 'red' : 'green'
                                }}>
                                    {rowData.update_entity}
                                </div>;
                            }}
                        </Cell>
                    </Column>
                    {isAdmin && <Column flexGrow={1} fixed="right">
                        <HeaderCell>Action</HeaderCell>
                        <Cell>
                            {rowData => {
                                function handleAction(action) {
                                    if (action === VIEW)
                                        return handleRowClick(rowData);
                                    setOpen(true);
                                    setSelectedRow(rowData);
                                }
                                return (<span>
                                    <a onClick={() => handleAction(VIEW)}>
                                        View
                                    </a> | <a onClick={() => handleAction(DELETE)}>
                                        Remove
                                    </a>
                                </span>);
                            }}
                        </Cell>
                    </Column>}
                </StyledTable>
            </CardBody>
        </StyledCardWrapper>
    )
}

export default DatatableComponent;