import React from 'react';
import Mentors from './components/mentors';
import Thankyou from './components/thankyou';
import Header from './components/appbar';
import Home from './components/home';

import { Routes, Route } from "react-router-dom";
import { AuthUserProvider } from './contexts/authprovider';

const App = () => {
	return (<AuthUserProvider>
		<div>
			<style jsx global>{`
			body {
				margin: 0px;
				padding: 0px;
				overflow: hidden;
			}
			`}</style>
			<link jsx global rel="icon" href="./assets/logo.png" />
		</div>
		<Header/>
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="dashboard" element={<Mentors />} />
			<Route path="thankyou" element={<Thankyou />} />
		</Routes>
	</AuthUserProvider>);
};

export default App;
