import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Modal } from './modal';
import { Box, Divider, Fab } from '@mui/material';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getFormsInfo } from '../data/forminfo';

import Carousel, { CarouselItem } from './carousel';

export const ButtonAction = styled.div`
    background: #ed1c24;
    border-radius: 3px;
    padding: 5px 10px;
    color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover {
        cursor: pointer;
        background: #fff;
        color: #ed1c24;
    }
`;

export const Container = styled.div`
    height: 500px;
    overflow-y: scroll;
    scroll-behavior: smooth;

    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-track {
    background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
    background: #888;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
        width: 9px;
        cursor: pointer;
    }

    @media only screen and (max-width: 768px) {
        &{
            height: 400px;
        }
    }
`;

export const Loading = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

export const Layout = styled.div`
    display: flex;
`;

export const Fields = styled.div`
    padding: 40px;
    position: relative;

    @media only screen and (max-width: 768px) {
        &{
            padding: 0;
        }
    }
`;

export const StyledFab = styled(Fab)`
    &&{
        position: fixed;
        bottom: 20px;
        right: 20px;
        background: #ed1c24;
        color: #fff;

        &:hover {
            background: #fff;
            color: #ed1c24;
        }
    }
`;

export const DetailsContainer = styled.div`
    border-left: 1px solid #c0bfbf;
    padding: 10px;
    min-width: 250px;
    max-height: 60vh;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const FieldContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 10px;
    min-height: 50px;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);

    @media only screen and (max-width: 768px) {
        &{
            display: block;
        }
    }
`;

export const Question = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 20px;

    @media only screen and (max-width: 768px) {
        &{
            padding-right: 0;
        }
    }
`;

export const Answers = styled.div`
    padding-left: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media only screen and (max-width: 768px) {
        &{
            padding-left: 0;
        }
    }
`;

export const Image = styled.img`
    max-width: 500px;
    padding: 10px;
    border-radius: 20px;
    width: ${({ click, slider }) => click || slider ? '150px' : '500px'};
    cursor: ${props => props.click ? 'pointer' : 'default'};

    @media only screen and (max-width: 768px) {
        &{
            max-width: 200px;
        }
    }
`;

export const ImageContainer = styled.div`
    max-width: 500px;
    border-radius: 10px;
`;

export const Status = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 5px;
    padding: 5px;
    background: ${props => props.isActive ? 'green' : 'red'};
`;

export const Iframe = styled.iframe`
    width: 800px;
    height: 700px;
`;

export const ScrollComponent = styled.div`
    margin: 0;
    padding: 0;
    height: 0;
`;

const Images = ({ images }) => {
    const [showImages, setShowImages] = useState(false);
    const [isSingleImg] = useState(typeof images === 'string');

    const close = () => setShowImages(false);

    return isSingleImg ? <Image src={images} alt="link" /> : <>
        <Modal handleClose={close} show={showImages} z={12} trans>
            <ImageSlider images={images} slider />
        </Modal>
        <ImageContainer onClick={() => setShowImages(true)}>
            <Image src={images[0]} click alt="link" />
        </ImageContainer> </>
};

const getFormattedAnswerfromType = (type, answer) => {
    const excludes = ['control_button', 'control_checkbox',
        'control_dropdown', 'control_matrix', 'control_rating',
        'control_slider', 'control_text', 'control_head'];
    if (excludes.includes(type)) return null;
    if (!answer) return '-';

    const getImage = (data) => <Image src={data} alt="link" />
    
    const handleFileUploads = (data) => {
        if (data.length === 0) return '-';
        if (data.length === 1) return getImage(data[0]);
        return <Images images={data} />
    }

    const datafromType = {
        control_dropdown: (data) => `${data}`,
        control_textarea: (data) => `${data}`,
        control_textbox: (data) => `${data}`,
        control_number: (data) => `${data}`,
        control_radio: (data) => `${data}`,
        control_email: (data) => `${data}`,
        control_phone: ({ area, phone }) => `${area}-${phone}`,
        control_fullname: ({ first, last }) => `${first} ${last}`,
        control_datetime: ({ month, day, year }) => `${day}/${month}/${year}`,
        control_address: ({ addr_line1: a, city: c, state: s, postal: p }) => `${a}, ${c}, ${s} ${p}`,
        control_signature: (data) => <Images images={data} />,
        control_fileupload: (data) => handleFileUploads(data),
        control_widget: (data) => data.includes('http') ? getImage(data) : data,
    };
    const formattedAnswer = datafromType[type] && datafromType[type](answer);
    return formattedAnswer;
};

const Field = ({ type, question, answer }) => {
    const formattedAnswer = getFormattedAnswerfromType(type, answer);
    return formattedAnswer && <FieldContainer>
        <Question>{question}</Question>
        <Answers>{formattedAnswer}</Answers>
    </FieldContainer>;
};

const sortByColumn = (arrayToSort, column) => {
    const sorted = arrayToSort.sort((a, b) => {
        if (a[column] < b[column]) {
            return -1;
        }
        if (a[column] > b[column]) {
            return 1;
        }
        return 0;
    });
    return sorted;
};

const ImageSlider = ({ images }) => {
    return <Carousel>{images.map((image, index) =>
        <CarouselItem key={index}>
            <Image src={image} alt="link" />
        </CarouselItem>)}
    </Carousel>
};

const Details = ({ submission, closeDetails }) => {
    const [showEdit, setShowEdit] = useState(false);
    const [sourceURL, setsourceURL] = useState('');
    const isActive = submission.status === 'ACTIVE';
    const formName = getFormsInfo()[submission.form_id].form_name;


    const toggleEditModal = () => setShowEdit(!showEdit);

    useEffect(() => {
        const editform = `https://www.jotform.com/edit/${submission.id}`;
        setsourceURL(editform);
    }, [submission.id]);

    return <DetailsContainer>
        <Modal handleClose={toggleEditModal} show={showEdit} z={12}>
            {sourceURL && <Iframe title="Jotform" src={sourceURL}
                allowTransparency="true"
                allowFullScreen="true"
                allow="geolocation; microphone; camera"
                frameBorder="0" scrolling="yes" />}
        </Modal>
        <div style={{ fontSize: '1.5rem', height: '4rem' }}>{formName}</div>
        <div style={{ display: 'flex', gap: '5px' }}>
            <ButtonAction onClick={toggleEditModal}>Edit</ButtonAction>
            <ButtonAction onClick={closeDetails}>Close</ButtonAction>
        </div>
        <Divider style={{ margin: '20px 0' }} />
        <div style={{ height: '2rem' }}>
            Created: {submission.created_at}
        </div>
        <div style={{ height: '2rem' }}>
            Updated: {submission.updated_at}
        </div>
        <Status isActive={isActive}>{submission.status}</Status>
        <Divider style={{ margin: '20px 0' }} />
        <div>Notes: {submission.notes}</div>
    </DetailsContainer>
};


const Submission = ({ submission, showToast }) => {
    const scrollRef = useRef(null);
    const [fields, setFields] = useState([]);
    const [showDetails, setShowDetails] = useState(false);

    const toggleShowDetails = () => {
        setShowDetails(!showDetails);
    };

    const scrollComponent = () => {
        scrollRef.current.scrollIntoView({ behavior: 'smooth' })
    };

    useEffect(() => {
        const answers = submission.answers;
        const ansArry = Object.keys(answers).map(k => (
            { ...answers[k], order: answers[k].order * 1 }));
        const fields = sortByColumn(ansArry, 'order');
        setFields(fields);
    }, [submission.answers]);

    return (
        <Container>
            <ScrollComponent ref={scrollRef} />
            {!showDetails && <ButtonAction onClick={toggleShowDetails}>
                Submission Details
            </ButtonAction>}
            <Layout>
                <Fields>
                    {fields.map(({ type, answer, text }, index) => (
                        <Box key={index}> {type === 'control_divider' ?
                            <Divider key={index} /> :
                            <Field type={type} answer={answer} question={text} />}
                        </Box>))}
                    <StyledFab onClick={scrollComponent}>
                        <KeyboardArrowUpIcon />
                    </StyledFab>
                </Fields>

                {showDetails && <Details submission={submission}
                    closeDetails={toggleShowDetails} />}
            </Layout>
        </Container>
    );
};


export default Submission;