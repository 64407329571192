import React, { useEffect } from 'react';
import styled from 'styled-components';

export const Links = styled.div`
    margin: 10px 20%;
    height: 90vh;
    overflow: hidden;
    scroll-behavior: smooth;
`;

export const Scrolly = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-right: 17px;
    box-sizing: content-box;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`;

export const Link = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    min-width: 200px;
    min-height: 200px;
    max-width: 200px;
    max-height: 200px;
    font-size: 1.5em;
    background: #1f1f1f;
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 3px 6px 20px 0px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;

    a {
        color: #ffffff;
        text-decoration: none;
    }
`;

const Home = () => {
    const links = [
        { name: 'C-NRPP Home', link: 'https://c-nrpp.ca' },
        { name: 'C-NRPP Certification', link: 'https://c-nrpp.ca/how-to-become-certified/' },
        { name: 'C-NRPP Courses', link: 'https://c-nrpp.ca/courses/' },
        { name: 'C-NRPP Exam', link: 'https://c-nrpp.ca/how-to-become-certified/exam-results-3/' },
        { name: 'C-NRPP Resources', link: 'https://c-nrpp.ca/resources-for-certified-professionals/a' },
        { name: 'C-NRPP Shop', link: 'https://c-nrpp.ca/shop/' },
    ];

    const handleOnclick = (link) => {
        window.location.href = link.link;
    };

    useEffect(() => {
        document.title = 'C-NRPP Mentorship';
    });

    return (
        <Links>
            <Scrolly>{
                links.map(link => (<Link onClick={() => handleOnclick(link)}>
                    <a href={link.link}>{link.name}</a>
                </Link>))
            }</Scrolly>
        </Links>
    )
}

export default Home;