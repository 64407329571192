import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';

import { CardWrapper, CardHeader, CardHeading, CardBody, CardFieldset, CardInput, CardSelect, CardButton } from "./card.js";


export const StyledCardWrapper = styled(CardWrapper)`
    width: 400px;
    padding: 40px;
    @media only screen and (max-width: 768px) {
        &{
            width: 100%;
            padding: 20px 0;
        }
    }
`;

const dummyData = [{
    "mentor_id": 1,
    "mentor_name": "Colin Dumais",
    "info": null,
    "added_date": "2020-02-12T20:45:14.000Z",
    "email": "colin@radonwest.com"
},
{
    "mentor_id": 2,
    "mentor_name": "Marcel Brascoupe",
    "info": null,
    "added_date": "2020-03-25T23:04:14.000Z",
    "email": "mbrascoupe@gmail.com"
},
{
    "mentor_id": 3,
    "mentor_name": "Pam Warkentin",
    "info": null,
    "added_date": "2020-04-21T18:16:32.000Z",
    "email": "p_warkentin@carst.ca"
}];

const ChangeMentee = ({ finishAction }) => {
    const [mentors, setMentors] = useState([]);
    const [email, setEmail] = useState('');
    const [newName, setNewName] = useState('');
    const [mentor, setMentor] = useState({
        mentor_id: '',
        email: '',
        mentor_name: ''
    });

    const handleMentorChange = (mentor_id) => {
        mentor_id = parseInt(mentor_id);
        if (!mentor_id) return toast('Please select a mentor', {
                theme: "colored" });
        const mentor = mentors.find(m => m.mentor_id === mentor_id);
        setMentor(mentor);
    };

    const handleChangeMentee = () => {
        if (!mentor.mentor_id || !email || !newName) 
        return toast.error('Please complete all selections', {
            theme: "colored" });

        axios.put(`/mentee/email/${email}`, {
            mentor: mentor.mentor_id,
            mentee_name: newName })
        .then(res => {
            toast.success('Mentee updated successfully', {
                theme: "colored" });
            finishAction();
        })
        .catch(err => {
            toast.error('Error updating mentee', {
                theme: "colored" });
        });
    };

    useEffect(() => {
        async function fetchData() {
            await axios.get('/mentor')
                .then(res => {
                    setMentors(res.data.length !== 0 ? res.data : dummyData);
                    res.data.length === 0 && toast.info('Dummy data used', {
                        theme: "colored"
                    });
                })
                .catch(err => {
                    setMentors(dummyData);
                    toast.info('Dummy data used', {
                        theme: "colored"
                    });
                });
        }
        fetchData();
    }, []);


    return (
        <StyledCardWrapper>
            <ToastContainer />
            <CardHeader>
                <CardHeading>Change Mentee details</CardHeading>
            </CardHeader>
            <CardBody>
                <CardFieldset>
                    <CardInput placeholder="Enter mentees email (Required)" type="text" required value={email} 
                        onChange={(e) => setEmail(e.target.value)}/>
                </CardFieldset>

                <CardFieldset>
                    <CardInput placeholder="Enter mentee's new name" type="text" required value={newName}
                        onChange={(e) => setNewName(e.target.value)}/>
                </CardFieldset>

                <CardFieldset>
                    <CardSelect placeholder="Select Mentor to Assign" type="text" required 
                        onChange={(e) => handleMentorChange(e.target.value)}>
                        {mentors && mentors.map(mentor => (
                            <option key={mentor.mentor_id} value={mentor.mentor_id}>
                                {mentor.mentor_name}
                            </option>
                        ))}
                    </CardSelect>
                </CardFieldset>

                <CardFieldset>
                    <CardButton type="button" onClick={finishAction}>Cancel</CardButton>
                </CardFieldset>
                <CardFieldset>
                    <CardButton type="button" onClick={handleChangeMentee}>Change Mentee</CardButton>
                </CardFieldset>
            </CardBody>
        </StyledCardWrapper>
    )
}

export default ChangeMentee;