import React from 'react';
import styled from 'styled-components';

export const ModalContainer = styled.div`
    position:fixed;
    background: ${props => props.trans ? 'transparent' : 'white'};
    max-width: 80%;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    z-index: ${props => props.z ? props.z : 10};
    border-radius: 10px;
    
    @media only screen and (max-width: 768px) {
        &{
            width: 100%;
            max-width: 98%;
        }
    }
  `;

export const Background = styled.div`
    display: ${props => props.show ? 'block' : 'none'};
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: ${props => props.z ? props.z : 9};
  `;

export const Close = styled.div`
    cursor: pointer;
    margin-top: -40px;
    margin-left: auto;
    width: 10px;
    height: 10px;
    border: 7px solid #f53100;
    border-radius: 5px;
    background:
        linear-gradient(45deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 43%,#fff 45%,#fff 55%,rgba(0,0,0,0) 57%,rgba(0,0,0,0) 100%),
        linear-gradient(135deg, #f53100 0%,#f53100 43%,#fff 45%,#fff 55%,#f53100 57%,#f53100 100%);

    @media only screen and (max-width: 768px) {
        &{
            margin-top: -30px;
            width: 10px;
            height: 10px;
        }
    }
`;

export const Modal = ({ handleClose, show, children, z, trans }) => {
    return (
        <Background show={show} z={z}>
            <ModalContainer z={z} trans={trans}>
                <Close onClick={handleClose} />
                {children}
            </ModalContainer>
        </Background>
    );
};
