import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from './modal';

import styled from 'styled-components';
import axios from 'axios';

import DatatableComponent from './datatable';
import AddMentor from './addMentor';
import ChangeMentee from './changeMentee';
import ChangeMentor from './changeMentor';


import { useAuth } from '../contexts/authprovider';

import Surveys from './survey';

export const Button = styled.button`
    background-color: #ed1c24;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin: 0 2px;
    font-size: 14px;
    cursor: pointer;
`;

const MODES = {
    NOTHING: -1,
    CHANGE_MENTEE: 0,
    CHANGE_MENTOR: 1,
    ADD_MENTOR: 2,
    SHOW_SURVEYS: 3
}

const Mentors = () => {
    const [mentor, setMentor] = useState(null);
    const [mode, setMode] = useState(MODES.NOTHING);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [menteeSelected, setMenteeSelected] = useState();
    const { authUser } = useAuth();
    
    const navigate = useNavigate();

    const handleRowSelected = (rowData) => {
        setMenteeSelected(rowData);
        setMode(MODES.SHOW_SURVEYS);
        setShow(true);
    };

    const handleCloseModal = () => {
        setShow(false);
        setMode(MODES.NOTHING);
    };

    const handleModeChange = (modeToSet) => {
        setMode(modeToSet);
        setShow(true);
    };

    const handleFinishAction = (mentor) => {
        switch (mode) {
            case MODES.ADD_MENTOR:
                console.log(mentor);
                break;
            case MODES.CHANGE_MENTOR:
                setMentor(mentor);
                break;
            case MODES.CHANGE_MENTEE:
                console.log(mentor);
                break;
            default:
                break;
        }
        handleCloseModal();
    };

    const handleTest = async () => {
        setLoading(true);
        const response = await axios.get('/auth', {withCredentials: true});
        const user = response.data.user;
        console.log({response, user});
        if (!user) { 
            navigate('/');
            window.location.href = '/';
        }
        setLoading(false);
    }

    useEffect(() => { handleTest(); }, []);

    useEffect(() => { document.title = 'C-NRPP Dashboard'; });
    
    useEffect(() => { 
        try {
            const calcIsAdmin = authUser.currentuser.user.role === 'ADMIN';
            setIsAdmin(calcIsAdmin);
        } catch (err) {
            console.log(err)
        }
    }, [authUser]);

    return (!loading && <>
        {show && mode !== MODES.SHOW_SURVEYS && <Modal handleClose={handleCloseModal} show={show}>
            {mode === MODES.CHANGE_MENTEE && <ChangeMentee finishAction={handleFinishAction} />}
            {mode === MODES.CHANGE_MENTOR && <ChangeMentor finishAction={handleFinishAction} />}
            {mode === MODES.ADD_MENTOR && <AddMentor finishAction={handleFinishAction} />}
        </Modal>}
        {isAdmin && <div>
            <div style={{ display: 'flex', justifyContent: 'center', margin: '5px auto' }}>Admin Center</div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={() => handleModeChange(MODES.ADD_MENTOR)}>Add Mentor</Button>
                <Button onClick={() => handleModeChange(MODES.CHANGE_MENTEE)}>Change Mentee</Button>
                <Button onClick={() => handleModeChange(MODES.CHANGE_MENTOR)}>Change Mentor</Button>
            </div>
        </div>}
        <div style={{ display: 'flex', justifyContent: 'center', margin: '5px auto' }}>Mentees</div>
        <DatatableComponent rowSelected={handleRowSelected} mentor={mentor} isAdmin={isAdmin}/>
        {show && mode === MODES.SHOW_SURVEYS && <Modal handleClose={handleCloseModal} show={show}>
            {mode === MODES.SHOW_SURVEYS && <Surveys mentee={menteeSelected} />}
        </Modal>}
    </>);
}

export default Mentors;