export const getFormsInfo = () => {
    const forms = {
        '91576494908271': {
            email: 'email',
            name: 'nameof',
            isSignup: true,
            form_name: 'Sign Up',
            last_updater: 'areyou',
        },
        '91576589281270': {
            email: 'menteeemail',
            form_name: 'Scene Survey',
            last_updater: 'mentor_mentee',
        },
        '91605601404245': {
            email: 'email',
            form_name: 'System Design Worksheet',
            last_updater: 'mentor_mentee'
        },
        '91606845815262': {
            email: 'menteesemail',
            form_name: 'Depressurization Report',
            last_updater: 'mentor_mentee'
        },
        '91614463957264': {
            email: 'emailaddress',
            form_name: 'Completed Mitigation',
            last_updater: 'mentor_mentee',
        }
    };
    return forms;
};

export const getEmailfromSubmisison = (submission) => {
    const forms = {
        "91576494908271": submission.answers['18'].answer,
		"91576589281270": submission.answers['77'].answer,
		"91605601404245": submission.answers['81'].answer,
		"91606845815262": submission.answers['54'].answer,
		"91614463957264": submission.answers['6'].answer
    }
    return forms;
}