import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';

import { CardWrapper, CardHeader, CardHeading, CardBody, CardFieldset, CardSelect, CardButton } from "./card.js";


export const StyledCardWrapper = styled(CardWrapper)`
    width: 400px;
    padding: 40px;
    @media only screen and (max-width: 768px) {
        &{
            width: 100%;
            padding: 20px 0;
        }
    }
`;

const dummyData = [{
    "mentor_id": 1,
    "mentor_name": "Colin Dumais",
    "info": null,
    "added_date": "2020-02-12T20:45:14.000Z",
    "email": "colin@radonwest.com"
},
{
    "mentor_id": 2,
    "mentor_name": "Marcel Brascoupe",
    "info": null,
    "added_date": "2020-03-25T23:04:14.000Z",
    "email": "mbrascoupe@gmail.com"
},
{
    "mentor_id": 3,
    "mentor_name": "Pam Warkentin",
    "info": null,
    "added_date": "2020-04-21T18:16:32.000Z",
    "email": "p_warkentin@carst.ca"
}];

const ChangeMentor = ({ finishAction }) => {
    const [mentors, setMentors] = useState([]);
    const [mentor, setMentor] = useState({
        mentor_id: '',
        email: '',
        mentor_name: ''
    });

    const handleMentorChange = (mentor_id) => {
        mentor_id = parseInt(mentor_id);
        if (!mentor_id) return toast('Please select a mentor', {
            theme: "colored"
        });
        const mentor = mentors.find(m => m.mentor_id === mentor_id);
        setMentor(mentor);
    };

    const handleChangeMentor = () => {
        if (!mentor || !mentor.mentor_id)
            return toast.error('Please select a mentor', {
                theme: "colored"
            });
        finishAction(mentor);
    };

    useEffect(() => {
        async function fetchData() {
            await axios.get('/mentor')
                .then(res => {
                    setMentors(res.data.length !== 0 ? res.data : dummyData);
                    res.data.length === 0 && toast.info('Dummy data used', {
                        theme: "colored"
                    });
                })
                .catch(err => {
                    setMentors(dummyData);
                    toast.info('Dummy data used', {
                        theme: "colored"
                    });
                });
        }
        fetchData();
    }, []);


    return (
        <StyledCardWrapper>
            <ToastContainer />
            <CardHeader>
                <CardHeading>Change Mentor</CardHeading>
            </CardHeader>
            <CardBody>
                <CardFieldset>
                    <CardSelect placeholder="Username" type="text" required
                        onChange={(e) => handleMentorChange(e.target.value)}>
                        <option key={'442'} value={''}>Select One Option</option>
                        {mentors && mentors.map(mentor => (
                            <option key={mentor.mentor_id} value={mentor.mentor_id}>
                                {mentor.mentor_name}
                            </option>
                        ))}
                    </CardSelect>
                </CardFieldset>

                <CardFieldset>
                    <CardButton type="button" onClick={finishAction}>Cancel</CardButton>
                </CardFieldset>

                <CardFieldset>
                    <CardButton type="button" onClick={handleChangeMentor}>Change Mentor</CardButton>
                </CardFieldset>
            </CardBody>
        </StyledCardWrapper>
    )
}

export default ChangeMentor;