import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';
import { Pagination, Stack } from '@mui/material';

export const CarouselContainer = styled.div`
    overflow: hidden;
`;

export const Inner = styled.div`
    white-space: nowrap;
    transition: transform 0.3s;
`;

export const CarouselItemContainer = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
`;

export const Indicators = styled.div`
    display: flex;
    justify-content: center;
    background: white ;
    border-radius: 4px;

    button {
        margin: 5px;
    }

    button.active {
        color: #fff;
    }
`;


export const CarouselItem = ({ children, width }) => {
    return (
        <CarouselItemContainer style={{ width: width }}>
            {children}
        </CarouselItemContainer>
    );
};

const Carousel = ({ children }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [paused, setPaused] = useState(false);

    const updateIndex = (newIndex) => {
        if (newIndex < 0) {
            newIndex = React.Children.count(children) - 1;
        } else if (newIndex >= React.Children.count(children)) {
            newIndex = 0;
        }
        setActiveIndex(newIndex);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (!paused) updateIndex(activeIndex + 1);
        }, 3000);

        return () => {
            if (interval) clearInterval(interval);
        };
    });

    const handlers = useSwipeable({
        onSwipedLeft: () => updateIndex(activeIndex + 1),
        onSwipedRight: () => updateIndex(activeIndex - 1)
    });

    const handleChange = (event, value) => {
        updateIndex(value);
        event.preventDefault();
    };

    return (
        <CarouselContainer {...handlers}
            onMouseEnter={() => setPaused(true)}
            onMouseLeave={() => setPaused(false)}>
            <Inner style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
                {React.Children.map(children, (child, index) => {
                    return React.cloneElement(child, { width: "100%" });
                })}
            </Inner>
            <Indicators>
                <Stack spacing={2}>
                    <Pagination count={children.length} variant="outlined"
                        page={activeIndex} onChange={handleChange} />
                </Stack>
            </Indicators>
        </CarouselContainer>
    );
};

export default Carousel;