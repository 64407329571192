import React, { useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Modal } from './modal';
import Authentication from './authentication';


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #f5f5f5;
`;

export const Thank = styled.div`
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background: green;
    padding: 3rem;
    border-radius: 5px;
`;

export const Linky = styled.div`
    cursor: pointer;
`;

const Thankyou = () => {
    const [show, setShow] = React.useState(false);
    const [user, setUser] = React.useState(null);

    const handleCloseModal = () => {
        setShow(false);
    };

    const handleShowModal = () => {
        setShow(true);
    };

    const finishAction = () => {
        handleCloseModal();
        window.location.href = '/';
    };

    const handleCheckLoggedIn = async () => {
        const response = await axios.get('/auth', {withCredentials: true});
        const user = response.data.user;
        if ( user ) { setUser( user ); }
    }

    useEffect(() => { handleCheckLoggedIn(); }, []);

    useEffect(() => {
        document.title = 'C-NRPP Thank you';
    });

    return (
        <Container>
            {show && <Modal handleClose={handleCloseModal} show={show}>
                <Authentication finishAction={finishAction}/>
            </Modal>}
            <Thank>Thank you for your submission, 
                your responses have been recorded</Thank>

            {!user && <Linky onClick={handleShowModal}>
                Mentor? Log in</Linky>}
        </Container>
    )
}

export default Thankyou;