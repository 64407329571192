import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Submission from './submission';
import { Tabs, Tab, Box, CircularProgress } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { getFormsInfo } from '../data/forminfo';
import { dummyResponse } from '../data/dummy';
import 'react-toastify/dist/ReactToastify.css';


export const Container = styled.div`
    width: 75vw;
    margin: 0 auto;
    min-height: 50vh;
    max-height: 80vh;
    @media only screen and (max-width: 768px) {
        &{
            width: 100%;
            padding: 0;
        }
    }
`;

export const Header = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1rem;
    padding: 0.5rem;
    border-bottom: 1px solid #ccc;
    text-align: center;

    @media only screen and (max-width: 768px) {
        &{
            padding: 0.25rem;
            margin-top: 0.5rem;
        }
    }
`;

export const Loading = styled.div`
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const TabPanel = (props) => {
    const { children, tab, index, ...other } = props;
    return (<div role="tabpanel" hidden={tab !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`} {...other}>
        {tab === index && (
            <Box sx={{ p: 1 }}>{children}</Box>
        )}
    </div>);
};

const Surveys = ({ mentee }) => {
    const [submissions, setSubmissions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newTab) => {
        setTab(newTab);
    };

    const showToast = (type, message) => {
        toast[type](message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };


    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            const menteeId = mentee.mentee_id;
            await axios.get(`/userforms/${menteeId}`)
                .then(res => {
                    res = res.data;
                    const submissions = res.submissions;
                    setSubmissions(submissions.length > 0 ? submissions : []);
                    setIsLoading(false);
                })
                .catch(err => {
                    console.log(err);
                    setSubmissions(dummyResponse ? dummyResponse.submissions : []);
                    setIsLoading(false);
                });
        }
        fetchData();
    }, []);

    return (<Container>
        <Header>Mentee</Header>
        <ToastContainer />
        {!isLoading && <Box sx={{ width: '100%', height: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={handleTabChange}
                    variant="scrollable" scrollButtons="auto">
                    {submissions.map((submission, index) => {
                        const { form_id } = submission;
                        const formName = getFormsInfo()[form_id].form_name;
                        return <Tab label={formName} id={`tab-${index}`}
                            style={{ textTransform: 'capitalize' }} key={index} />
                    })}
                </Tabs>
            </Box>
            {submissions.map((submission, index) => (
                <TabPanel tab={index} index={tab} key={index}>
                    <Submission submission={submission} showToast={showToast} />
                </TabPanel>))}
        </Box>}
        {isLoading && <Loading><CircularProgress /></Loading>}
    </Container>);
}

export default Surveys;