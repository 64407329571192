import React, { useState } from 'react';
import styled from 'styled-components';

import { useAuth } from '../contexts/authprovider';

import { ToastContainer, toast } from 'react-toastify';

import { CardWrapper, CardHeader, CardHeading, CardBody, 
    CardFieldset, CardInput, CardButton } from "./card.js";

const modes = {
    SIGNIN: 'signin',
    SIGNUP: 'signup',
    RESETPASSWORD: 'resetpassword'
}

export const StyledCardWrapper = styled(CardWrapper)`
    width: 400px;
    padding: 40px;
    @media only screen and (max-width: 768px) {
        &{
            width: 100%;
            padding: 20px 0;
        }
    }
`;

export const AdditionalAction = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-size: 14px;
    color: #494949;
    cursor: pointer;
`;

const Authentication = ({ finishAction }) => {
    const [mode, setMode] = useState(modes.SIGNIN);
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [showCode, setShowCode] = useState(false);
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const { signInWithEmailAndPassword, sendCode, resetPassword } = useAuth();


    const handleAuthentication = () => {
        if ( !email || !password ) 
            return toast.error('Please complete all selections', {
                theme: "colored" });
        signInWithEmailAndPassword(email, password)
            .then(() => {  finishAction(); })
            .catch(() => {});
    };

    const handleSendCode = () => {
        if ( !email || !newPassword || !confirmPassword)
            return toast.error('Please enter all information', {
                theme: "colored" });

        sendCode(email, newPassword, confirmPassword)
                .then(() => { setShowCode(true); })
                .catch(() => { });
    };

    const handleResetPassword = () => {
        if ( !email || !newPassword || !confirmPassword || !code ) 
            return toast.error('Please complete all selections', {
                theme: "colored" });

            resetPassword(email, newPassword, confirmPassword, code)
                .then(() => { finishAction();})
                .catch(() => { });
    };

    return (
        <StyledCardWrapper>
            <ToastContainer />
            <CardHeader>
                {mode === modes.SIGNIN && <CardHeading>Sign in</CardHeading>}
                {mode === modes.SIGNUP && <CardHeading>Sign up</CardHeading>}
                {mode === modes.RESETPASSWORD && <CardHeading>Reset Password</CardHeading>}
            </CardHeader>
            <CardBody>
                <CardFieldset>
                    <CardInput placeholder="Email" type="text" required value={email} 
                        onChange={(e) => setEmail(e.target.value)}/>
                </CardFieldset>

                {mode === modes.SIGNIN && <CardFieldset>
                    <CardInput placeholder="Password" type="password" required value={password}
                        onChange={(e) => setPassword(e.target.value)}/>
                </CardFieldset>}

                {mode === modes.RESETPASSWORD && !showCode && <CardFieldset>
                    <CardInput placeholder="New Password" type="password" required value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}/>
                </CardFieldset>}

                {mode === modes.RESETPASSWORD && !showCode && <CardFieldset>
                    <CardInput placeholder="Confirm New Password" type="password" required value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}/>
                </CardFieldset>}

                {mode === modes.RESETPASSWORD && showCode && <CardFieldset>
                    <CardInput placeholder="Confirm Code" type="text" required value={code}
                        onChange={(e) => setCode(e.target.value)}/>
                </CardFieldset>}

                <CardFieldset>
                    <CardButton type="button" onClick={finishAction}>Cancel</CardButton>
                </CardFieldset>

                {mode === modes.SIGNIN && <CardFieldset>
                    <CardButton type="button" onClick={handleAuthentication}>Login</CardButton>
                </CardFieldset>}

                {mode === modes.RESETPASSWORD && !showCode && <CardFieldset>
                    <CardButton type="button" onClick={handleSendCode}>Send Code</CardButton>
                </CardFieldset>}

                {mode === modes.RESETPASSWORD && showCode && <CardFieldset>
                    <CardButton type="button" onClick={handleResetPassword}>Reset Password</CardButton>
                </CardFieldset>}

                {mode === modes.SIGNIN && <AdditionalAction onClick={() => setMode(modes.RESETPASSWORD)}>
                    Forgot Password
                </AdditionalAction>}

                {mode === modes.RESETPASSWORD && <AdditionalAction onClick={() => setMode(modes.SIGNIN)}>
                    Cancel/Sign In
                </AdditionalAction>}
            </CardBody>
        </StyledCardWrapper>
    )
}

export default Authentication;