import * as React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';


import { Modal } from './modal';
import { useAuth } from '../contexts/authprovider';

import Authentication from './authentication';
import PersonIcon from '@mui/icons-material/Person';


import { ToastContainer, toast } from 'react-toastify';

export const StyledContainer = styled(Container)`
    && {
        background: #1f1f1f;
    }
`;

const pages = ['Home', 'Dashboard', 'C-NRPP'];
const settings = ['Profile', 'Dashboard', 'Logout'];

const Header = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const [show, setShow] = React.useState(false);
    const [user, setUser] = React.useState(null);
    
    const { authUser, signOut, refreshToken } = useAuth();

    const handleCloseModal = () => {
        setShow(false);
    };

    const handleShowModal = () => {
        setShow(true);
    };

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (e, page) => {
        e.preventDefault();
        if (!page) return;
        
        switch (page) {
            case 'Home':
                window.location.href = '/';
                break;
            case 'Dashboard':
                if (!authUser) {
                    return toast.error('Please log in', { theme: "colored" });
                }
                window.location.href = '/dashboard';
                break;
            case 'C-NRPP':
                window.location.href = 'https://c-nrpp.ca';
                break;
            default:
                break;
        }
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = (e, setting) => {
        e.preventDefault();
        if (!setting) return;

        switch (setting) {
            case 'Profile':
                window.location.href = '/';
                break;
            case 'Dashboard':
                window.location.href = '/dashboard';
                break;
            case 'Logout':
                signOut();
                break;
            default:
                break;
        }

        setAnchorElUser(null);
    };

    const handleCheckLoggedIn = async () => {
        const response = await axios.get('/auth', {withCredentials: true});
        const user = response.data.user;
        if ( user ) { setUser( user ); }
    }

    const finishAction = () => {
        handleCloseModal();
        window.location.href = '/';
    };


    React.useEffect(() => { handleCheckLoggedIn(); }, []);

    React.useEffect(() => {
        const getToken = async () => await refreshToken();
        if (!authUser) 
            getToken().then(r => console.log({r}));
    }, []);

    return (<AppBar position="static">
        {show && <Modal handleClose={handleCloseModal} show={show}>
            <Authentication finishAction={finishAction}/>
        </Modal>}

        <ToastContainer />
        <StyledContainer maxWidth="xl">
            <Toolbar disableGutters>
                <img src={require('../assets/logo.png')} height={50} width={70} alt='logo'
                sx={{ display: { xs: 'none', md: 'flex' }, mr: 10, gap: 5 }}
                style={{ marginRight: '10px' }} />
                <Typography variant="h6" noWrap component="a" href="/" sx={{
                    mr: 2,
                    display: { xs: 'none', md: 'flex' },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                }} >  C-NRPP MENTORSHIP </Typography>

                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton size="large" aria-label="account of current user"
                        aria-controls="menu-appbar" aria-haspopup="true"
                        onClick={handleOpenNavMenu} color="inherit">
                        <MenuIcon />
                    </IconButton>
                    <Menu id="menu-appbar" anchorEl={anchorElNav} keepMounted
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        open={Boolean(anchorElNav)} onClose={handleCloseNavMenu}
                        sx={{ display: { xs: 'block', md: 'none' }, }} >
                        {pages.map((page) => (
                            <MenuItem key={page} onClick={e => handleCloseNavMenu(e, page)}>
                                <Typography textAlign="center">{page}</Typography>
                            </MenuItem>
                        ))}
                        {!user && <MenuItem onClick={handleShowModal}>
                            <Typography textAlign="center">{'Log In'}</Typography>
                        </MenuItem>}
                    </Menu>
                </Box>
                <Typography variant="p" noWrap component="a" href="" sx={{
                    mr: 2, display: { xs: 'flex', md: 'none' }, flexGrow: 1, 
                    fontFamily: 'monospace', fontWeight: 700, letterSpacing: '.3rem',
                    color: 'inherit',  textDecoration: 'none',
                }} >  C-NRPP MENTORSHIP </Typography>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    {pages.map((page) => (
                        <Button key={page} onClick={e => handleCloseNavMenu(e, page)}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        > {page} </Button>
                    ))}
                    
                    {!user && <Button onClick={handleShowModal}
                        sx={{ my: 2, color: 'white', display: 'block' }}>
                        {'Log In'}
                    </Button>}
                </Box>

                <Box sx={{ flexGrow: 0 }}>
                    {authUser && <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar alt="Remy Sharp"> <PersonIcon /> </Avatar>
                        </IconButton>
                    </Tooltip>}
                    <Menu sx={{ mt: '45px' }} id='menu-appbar' anchorEl={anchorElUser}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                        keepMounted
                        transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                        open={Boolean(anchorElUser)} onClose={handleCloseUserMenu} >
                        {settings.map((setting) => (
                            <MenuItem key={setting} onClick={e => handleCloseUserMenu(e, setting)}>
                                <Typography textAlign="center">{setting}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            </Toolbar>
        </StyledContainer>
    </AppBar>)
}

export default Header;